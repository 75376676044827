<template>
    <div v-if="rows.length">
        <apexchart
            :height="160"
            type="line"
            :options="chart_options"
            :series="chart_data"
        />

        <h5>{{ rows[rows.length - 1].reported_at | nibnut.date("MMM dd") }}</h5>
        <data-table
            id="zerospam-latest-threats"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="false"
            :show-totals="false"
            :searchable="false"
            :clickable="false"
            class="non-sticky"
            @sort="sort_by"
            @page="goto_page"
        >
            <template
                v-slot:summary="{ row }"
            >
                <div>{{ $root.translate('Spam Blocked') }}:&nbsp;{{ row.spam_count | nibnut.number("0,0") }}</div>
                <div>{{ $root.translate('Threats Blocked') }}:&nbsp;{{ row.threat_count | nibnut.number("0,0") }}</div>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <span v-if="field === 'remote_id'">
                    {{ row.remote_id | nibnut.date }}
                </span>
                <span v-else-if="field.match(/_count$/)">
                    {{ row[field] | nibnut.number("0,0") }}
                </span>
                <span v-else>{{ row[field] }}</span>
            </template>
        </data-table>
    </div>
</template>

<script>
import parseISO from "date-fns/parseISO"
import format from "date-fns/format"

import is_report from "./IsReport"

export default {
    name: "ZerospamReport",
    mixins: [is_report],
    computed: {
        state_identifier () {
            return "zerospam-report"
        },
        chart_raw_data () {
            const data = {}
            this.rows.forEach(row => {
                const date = parseISO(row.reported_at)
                const index = format(date, "yyyy-MM-dd")
                if(!data[index]) data[index] = { category: format(date, "MM-dd"), spam_count: 0, threat_count: 0 }
                data[index].spam_count += row.spam_count
                data[index].threat_count += row.threat_count
            })

            const dates = Object.keys(data)
            dates.sort()
            const raw_data = {}
            dates.forEach(date => {
                raw_data[data[date].category] = { spam_count: data[date].spam_count, threat_count: data[date].threat_count }
            })

            return raw_data
        },
        chart_options () {
            const categories = Object.keys(this.chart_raw_data)

            return {
                chart: {
                    id: "line-zerospam",
                    height: 160,
                    type: "line",
                    toolbar: {
                        show: false
                    }
                },
                grid: {
                    xaxis: {
                        lines: {
                            show: false
                        }
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    type: "dateTime",
                    tickAmount: 15,
                    categories
                },
                yaxis: {
                    forceNiceScale: true,
                    labels: {
                        formatter: (value) => {
                            return this.$numeral(value).format("0,0")
                        }
                    }
                }
            }
        },
        chart_data () {
            const values = Object.values(this.chart_raw_data)
            return [
                {
                    name: this.$root.translate("Spam"),
                    data: values.map(data => data.spam_count)
                },
                {
                    name: this.$root.translate("Threat"),
                    data: values.map(data => data.threat_count)
                }
            ]
        }
    },
    data () {
        return {
            columns: {
                remote_id: { label: "Date", sort: "desc" },
                spam_count: { label: "Spam Blocked", sort: false },
                threat_count: { label: "Threats Blocked", sort: false }
            },

            default_state: {
                per_page: 5,
                page: 1,
                sort_by: "id",
                sort_dir: "desc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            }
        }
    }
}
</script>
